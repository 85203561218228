@import "src/scss/mixins";
@import "src/scss/variables";

.footer {

  &-top {
    @include flexBox;
    width: 90%;
    margin: 20px auto;

    @include respond-to(sm) {
      flex-direction: column;
    }
  }

  &-text {
    width: 50%;

    @include respond-to(sm) {
      width: 90%;
    }
  }

  &-button {
    margin-top: 42px;
    background-color: $primary;
    padding: 12px 72px;
    border: none;
    border-radius: 10px;
    color: $textColor;
    letter-spacing: .5px;

    @include respond-to(sm) {
      margin: 20px 0px;
    }
  }

  &-para {
    opacity: 0.5;
  }

  &-links {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 50%;

    @include respond-to(sm) {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .footer-item{
      text-align: left;
    }
  }


  &-item {
    font-size: 12px;

    &>a {
      text-decoration: none !important;
    }
  }


  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.215);
    width: 80vw;
    margin: 10px auto;

    @include respond-to(sm) {
      flex-direction: column;
      padding: 10px;
    }

    p {
      font-size: 13px;
      margin: 0px;

      @include respond-to(sm) {
        margin: 10px auto;
      }
    }
  }

  &-bottom-right {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100px;
      object-fit: contain;
      margin: 0px 10px;
    }
  }
}