@import "src/scss/variables";
@import "src/scss/mixins";


.about{
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $bgColor;

    @include respond-to(lg){
        flex-direction: column;
    }
    &-section{
        background-color: $bgColor;
        padding: 40px;

        @include respond-to(md){
            padding: 20px;
        }
    }
    &-content{
        max-width: 40%;

        @include respond-to(lg){
            max-width: 100%;
            width: 100%;
        }

    }
    &-image-box{
        width: 40%;

        @include respond-to(md){
            max-width: 100%;
            display: none;
        }

    }
    &-image{
        width: 100%;
        transition: .5s;
    }

    &-head{
        font-size: 35px;
        font-weight: 700;
        margin: 60px;

        span{
            color: $primary;
        }
        @include respond-to(md){
            margin: 20px 10px;
        }
    }

    &-chead{
        font-size: 14px;
        color: $primary;
        font-weight:500;
        letter-spacing: 0px;
    }
    &-cpara{
        font-size: 12px;
        margin: 0px;
        color: $darkText;
    }
    .line-selected{
        transition: .5s;
    }

    &-image-box{
        text-align: center;
    }
    &-image{
        width: 60%;
    }
    &-cbox{
        height: 110px;
        cursor: pointer;

        @include respond-to(sm){
            height: fit-content;
            padding: 10px 0px;
            width: 100%;
        }
    }
}
