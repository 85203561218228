@import "variables";
@import "mixins";
@import "bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap');

body {
  font-family: 'Manrope', sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  letter-spacing: 1px;
}

.qr-code {
  height: 116px;
  width: 116px;
  position: fixed;
  z-index: 50;
  bottom: 48px;
  left: 32px;
  background-color: #DDDFE9;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  transform-origin: bottom left;
  transition: all 0.15s;
}

.qr-code:hover {
  scale: 2;
}

.qr-code img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.about-image-box {
  position: relative;
}

.phone-case-img {
  position: absolute;
  top: -10px;
  left: 50%;
  height: 102%;
  width: 64%;
  transform: translateX(-50%);
}