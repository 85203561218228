@import "src/scss/mixins";
@import "src/scss/variables";


.overview {
    background-color: $bgColor;

    &-text {
        text-align: center;
    }

    &-head {
        font-size: 35px;
        font-weight: 700;
        margin: 20px 0px;

        span {

            color: $primary;
        }

        @include respond-to(lg) {
            margin: 20px 20px;
        }
    }

    &-list-item {
        font-size: 13px;
        text-align: left;
        width: fit-content;
        margin: 5px auto;

        @include respond-to(md) {
            font-size: 15px;
        }
    }
}


.slider {
    display: flex;
    align-items: center;
    justify-content: space-around;

    &-left-box,
    &-right-box {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border: 1px solid $primary;
        border-radius: 200px;

        @include respond-to(lg) {
            display: none;
        }
    }

    &-left-icon,
    &-right-icon {
        font-size: 20px;
        color: $primary;
    }

    &-right-box {
        background-color: $primary;
    }

    &-right-box:hover,
    &-left-box:hover {
        scale: 0.95;
        transition: 0.5s;
    }

    &-right-icon {
        color: $textColor;
    }

    &-images {
        height: 410px;
        display: flex;
        align-items: center;
        position: relative;
        width: 49vw;

        @include respond-to(md) {
            width: 100vw;
            overflow-x: hidden;
        }


        .right1 {
            position: absolute;
            left: 520px;
            height: 350px;
            width: auto;

            @include respond-to(sm) {
                left: 340px;
            }
        }

        .right2 {
            position: absolute;
            left: 390px;
            height: 370px;
            width: auto;
            z-index: 1;

            @include respond-to(sm) {
                left: 210px;
            }
        }

        .right2.phone-case {
            left: 387px;

            @include respond-to(sm) {
                left: 208px;
            }
        }

        .center {
            top: 10px;
            position: absolute;
            left: 251px;
            height: 390px;
            width: auto;
            z-index: 100;

            @include respond-to(sm) {
                left: 80px;
            }
        }

        .center.phone-case {
            height: 410px;
            left: 242px;
            width: 195px;
            top: -2px;

            @include respond-to(sm) {
                left: 72px;
            }
        }

        .left2 {
            position: absolute;
            left: 130px;
            height: 370px;
            width: auto;
            z-index: 1;

            @include respond-to(sm) {
                left: -50px;
            }
        }

        .left2.phone-case {
            height: 390px;
            left: 122px;
            top: 13px;

            @include respond-to(sm) {
                left: -50px;
            }
        }

        .left1 {
            position: absolute;
            left: 0px;
            top: 20px;
            height: 350px;
            width: auto;

            @include respond-to(sm) {
                left: -180px;
            }
        }

        .left1.phone-case {
            height: 355px;
            left: -8px;
            top: 12px;

            @include respond-to(sm) {
                left: -180px;
            }
        }

    }

    &-indi-box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        max-width: 200px;
        margin: 10px auto;
    }

    &-indicator {
        width: 10px;
        height: 10px;
        background-color: rgba(190, 190, 190, 0.685);
        border-radius: 200px;
        // margin: 10px;
    }

    &-image {
        width: 130px;
        transition: .5s;
    }




}


.selected {
    // border: 1.5px solid rgb(255, 255, 255);
    // outline: 1px solid $primary;
    background-color: $primary;
}

.selected-image {
    width: 180px;
    transition: .5s;
}