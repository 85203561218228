@import 'src/scss/mixins';
@import 'src/scss/variables';


.contact-page{
    
    &-content{
        @include flexBox;
        justify-content: space-around;
        height: 100vh;
        
        @include respond-to(sm){
            flex-direction: column;
        }
    }

    &-head{
        margin:20px 0px;
        font-size: 35px;

        @include respond-to(sm){
            font-size: 30px;
        }
    }
    &-logo{
        @include respond-to(sm){
            display: none;
        }
    }
    &-info-card{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: fit-content;
        img{
            width: 20px;
            height: 20px;
            object-fit: contain;
        }
        p{
            margin: 0px 5px;
            width: 70%;
            margin-right: auto;
        }
    }

    &-form{
        background-color: rgb(33, 33, 33);
        padding: 10px;
        border-radius: 10px;
        color: white;
        width:400px;
        text-align: center;

        @include respond-to(sm){
            width: 90vw;
        }


        &-head{
            font-size: 20px;
            font-weight: bold;
            width: 90%;
            margin: 5px auto;
            text-align: left;
        }

        &-row{
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        &-input{
            background-color: black;
            border: 1px solid darkgrey;
            border-radius: 10px;
            width: 90%;
            padding: 5px;
            color: white;
        }
        &-label{
            font-size: 13px;
            margin: 5px 0px;
            width: 90%;
            text-align: left;
            font-family:'Manrope', sans-serif;
        }
        &-btn{
            background-color: $primary;
            color: $textColor;
            width: 90%;
            margin: 10px auto;
            padding: 10px;
            border: .5px solid rgb(33, 33, 33);
            border-radius: 10px;
        }
    }
}