$breakpoints: (
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "No such breakpoint: #{$breakpoint}.";
  }
}


@mixin flexBox{
  display: flex;
  align-items: center;
  justify-content: center;
}