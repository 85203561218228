@import "src/scss/variables";
@import "src/scss/mixins";


.security{
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    overflow: hidden;
    @include respond-to(lg){
        height: auto;
        flex-direction: column;
    }


     div{
        width: 50%;
        @include respond-to(lg){
            width: 90%;
        }
    }

    img{
        width: 50%;
        @include respond-to(lg){
            width: 120%;
        }


    }
    &-head{
        font-size: 40px;
        font-weight: 600;
        height: 12vh;
        &-span{
            color: $primary;
        }

        @include respond-to(xl){
            font-size: 35px;
            height: auto;
            margin:20px 0px
        }
    }

    &-para{
        opacity: 0.7;
    }

    &-chip{
        padding: 7px 5px;
        font-size: 12px;
        background-color: rgb(255, 255, 255);
        border-radius: 200px;
        width: fit-content;
        text-align: center;
        margin: 5px 10px;

        @include respond-to(sm){
            width: fit-content !important;
            margin-right: auto;
            padding: 7px 15px;
        }
        &-box{
            height: 15vh;
            width: 60% !important;
            @include flexBox;

            @include respond-to(xl){
                height: fit-content;
            }
            
        }
        p{
            margin: 0px;
            opacity: 0.7;
        }
    }
}