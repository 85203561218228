@import 'src/scss/mixins';
@import 'src/scss/variables';

.faq{
    @include flexBox;

    @include respond-to(sm){
        flex-direction: column;
    }
    padding: 100px 0px;
    &-left{
        width: 25%;

        @include respond-to(sm){
            width: 90%;
        }
        h1{
            font-size: 35px;
        }
        p{
            font-size: 12px;
            width: 100%;
        }
        img{
            width: 100%;
            object-fit: contain;
        }
    }

    &-right{
        width: 60%;

        @include respond-to(sm){
            width: 90%;
            margin: 30px 0px;
        }
    }

    &-card{
        width: 60%;
        margin-left: auto;
        height: 70px;
        overflow: hidden;
        transition: .5s;

        @include respond-to(sm){
            width: 100%;
        }
        &-toggle{
            background-color: #fafafa;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            border-radius: 10px;
            cursor: pointer;
            p{
                margin: 0px;
                font-size: 12px;
            }
        }

        &-content{
            p{
                font-size: 12px;
                line-height: 20px;
                margin-top: 10px;
            }
        }
    }

    &-card-selected{
        min-height: fit-content;
        height: 170px;
        .faq-card-toggle{
            background-color: $primary;

            p{
                color: white;
            }

            i{
                color: white;
            }
        }
    }


}


.selected{

}