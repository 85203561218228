@import "src/scss/variables";
@import "src/scss/mixins";

.partner{

    @include respond-to(lg){
        max-width: 90vw;
    }
    &-head{
        color: $primary;
        
    }
    &-tab-box{
        @include flexBox;
        justify-content: space-around;
        margin: 20px 0px;
        @include respond-to(sm){
            flex-direction: column;
        }
    }
    &-tab{
        width: 200px;
        height: 40px;
        @include flexBox;
        p{
            font-size: 13px;
            margin: 0px 5px;
            color: $primary;
        }
        img{
            height: 17px;
            width: 17px;
            object-fit: contain;
        }
        background-color: $bgColor;
        border-radius: 10px;
        cursor: pointer;

        @include respond-to(sm){
            width: 90%;
            height: 60px;
            margin: 10px auto;
        }
    }

    &-item{
        @include flexBox;
        padding: 20px 0px;
        @include respond-to(sm){
            height: 600px;
            flex-direction: column;
        }
    }

    &-content{
        display: grid;
        grid-template-columns: auto auto;

        @include respond-to(sm){
            grid-template-columns: auto
        }
    }
    &-image-box{
        width: 40%;
        @include respond-to(sm){
            width: 100%;
        }
    }
    &-image{
        width: 100%;
    }
    &-text{
        width: 60%;
        
        @include respond-to(sm){
            width: 90%;
        }
    }
    &-para{
        font-size: 13px;
        width: 80%;

        @include respond-to(sm){
            width: 100%;
        }
    }
    &-list-item{
        font-size: 12px;
        margin:10px 0px;
        width: 80%;

        @include respond-to(sm){
            width: 100%;
        }
    }

}

.selected-tab{
    background-color: $primary;
    p{
        color: $textColor;
    }
}