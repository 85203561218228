@import "src/scss/variables";
@import "src/scss/mixins";

.home {
  @include flexBox;
  margin: 0px;

  @include respond-to(lg) {
    flex-direction: column;
  }

  &-section {
    @include respond-to(lg) {
      max-width: 90vw;
      margin: 0px;
    }
  }

  &-title {
    margin-bottom: 42px;
    margin-top: 42px;

    span {
      font-size: 42px;
      letter-spacing: 1px;
      font-weight: 700;
      line-height: 60px;

      @include respond-to(sm) {
        font-size: 35px !important;
        line-height: 40px;
      }

    }

    @include respond-to(sm) {
      margin-top: 100px !important;
    }

    @include respond-to(md) {
      margin-top: 100px;
    }

    @include respond-to(lg) {
      margin-top: 100px;
    }



    &-green {
      color: $primary;
    }


  }

  &-para {
    opacity: 0.6;

    @include respond-to(sm) {
      line-height: 30px;
      letter-spacing: 1px;
    }
  }

  &-button {
    margin-top: 42px;
    border: 2px solid $primary;
    background-color: transparent;
    padding: 12px 72px;
    border-radius: 10px;
    font-weight: bold;
    color: $primary;
    letter-spacing: .5px;

    @include respond-to(sm) {
      padding: 12px 20px;
      margin-bottom: 20px;
    }
  }

  &-image {
    height: auto;
    width: 100%;
    margin: 0px;
    height: 600px;
    width: auto;
    margin: 0;

    @include respond-to(sm) {
      width: 100vw;
      height: auto;
    }

  }
}

.hero-link {
  text-decoration: none;
  width: 13vw;
  color: $darkText
}

.hero-text {
  height: 90vh;
  display: flex;
  align-items: center;
}