@import "src/scss/variables";
@import "src/scss/mixins";

.header {
    @include flexBox;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    padding: 20px 50px;
    justify-content: space-between;

    @include respond-to(sm){
        padding: 20px 10px;
    }
}